$dark: #24292e;
$teal: #13485a;
$delete: #e91e69;
$cancel: #9e9e9e;
$approve: hsl(144, 57%, 55%);
$MediumGray: #606367;
$lightGreen: #5ccfb3;
$MediumBlue: #337991;
$DarkBlue: #22556b;
$body: #e1edf3;

body {
  background-color: $body !important;
  font-weight: 200 !important;
  background-size: cover;
  background-repeat: no-repeat;
  img {
    display: block;
    width: 100%;
  }
  ::-moz-selection {
    background: $lightGreen;
  }
  ::selection {
    background: $lightGreen;
  }
}
small {
  clear: both;
  font-size: 12px !important;
}
h2,
h1,
h3,
h4 {
  font-weight: 800 !important;
}
h2 {
  margin-bottom: 0.5rem;
  line-height: 1.2;
  font-size: 24px !important;
}

hr {
  width: 100%;
}
*:focus {
  outline: 0 none;
  outline-offset: 0;
}
h1 img {
  width: 100px;
}
body .p-datatable .p-datatable-tbody > tr > td {
  border-top: 2px solid white !important;
  border-bottom: 1px solid #c8c8c8 !important;
  font-size: 12px;
}
body .p-datatable .p-sortable-column.p-highlight,
body .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: black !important;
}
.input-data-wrapper {
  &-remove-users {
    width: 15%;
    display: inline-block;
    vertical-align: middle;
  }

  padding: 0.25em;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  &-users,
  &-holdings {
    &.wide {
      width: 25%;
    }
    width: 20%;
    display: inline-block;
    .p-inputtext {
      width: 90%;
    }

    &.tipo_de_carga {
      width: 100%;
      #tipo_de_carga {
        width: 50%;
      }
    }
  }
  label {
    display: block;
    float: left;
    width: 22.5%;
    color: black;
    font-weight: 600;
    text-align: right;
  }
  &-input {
    float: right;
    width: 70%;
    color: black;
    font-size: 14px;
    margin: 10px;
  }
  small {
    float: right;
    text-align: right;
  }
}
span.required {
  color: $delete;
}
.p-dropdown {
  height: 35px;
  min-width: auto !important;
  label {
    text-align: left;
  }
}

.click-template {
  cursor: pointer;
  &:hover {
    color: #145b71;
    text-decoration: underline;
  }

  &-issn {
    border: 1px solid green;
  }
}

body .p-tabview .p-tabview-panels {
  border: none !important;
  margin-top: 2em;
  border-radius: 1em;
}
body {
  font-family: "Noto Sans JP", "Helvetica", Haettenschweiler, "Arial Narrow Bold",
    sans-serif !important;

  .p-component {
    clear: both;
  }
}

body .p-component {
  clear: both;
}
body .p-button.p-button-text-icon-left .p-button-text {
  font-size: 12px;
  vertical-align: baseline;
  margin-right: 10px;
}
body .p-button.p-button-success {
  background-color: $MediumBlue !important;
  border-color: $MediumBlue !important;
  &:hover {
    transition: cubic-bezier(0.215, 0.61, 0.355, 1);
    background-color: #23282d !important;
  }
}
body .p-button {
  background-color: #23282d !important;
  border: 1px solid #21252a !important;
}
.p-button-icon-left {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  margin-right: 5px;
}
.p-button.approve {
  background-color: $approve !important;
  border-color: $approve !important;
}
.p-button.remove {
  background-color: $delete !important;
  border-color: #e91e69 !important;
  &:hover {
    background-color: #23282d !important;
    border-color: #23282d !important;
  }
}

.p-button.cancel {
  background-color: $cancel !important;
  border-color: $cancel !important;
}
.nav-tabs {
  margin-top: 50px;
  &.nav-link {
    font-size: 16px;
  }
}

.tab-content {
  width: 100%;
  background: white;
  padding: 2em;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  .form-data-wrapper {
    display: block;
    max-width: 960px;
    margin: 0 auto;
    &.public {
      h2 {
        margin: 10px;
      }
      .input-data-wrapper {
        padding: 0;
      }
    }
    .clear {
      clear: both;
      width: 100%;
      overflow: hidden;
      border-bottom: 1px solid #dee2e6;
      padding-bottom: 25px;
    }
    img {
      width: 200px;
      float: left;
    }
  }
}

.container {
  width: 100% !important;
  max-width: 100% !important;
}
footer.footer {
  padding: 2em;
  text-align: center;
}
.header-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  .filters-wrapper {
    i,
    input {
      display: inline-block !important;
    }
    input {
      width: 90% !important;
    }
  }
}

.header-menu {
  padding: 0;
  margin: 0;
}
.header-wrap {
  .col-1:first-of-type {
    min-width: 150px;
  }
  &-headline {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  &-user-type-wrapper {
    display: inline;
  }
  &-user-type-pill {
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    color: white;
    padding: 0.5em;
    font-weight: 700;
    display: inline-block;
    margin: 0 5px;
    border-radius: 0.25em;
    &.admin {
      background-color: $delete;
    }
    &.regular {
      background-color: green;
    }
    &.orange {
      background-color: orange;
    }
  }
  font-size: 14px;
  .p-button {
    background-color: transparent !important;
    border: none !important;
    color: black;
    &:hover {
      color: black !important;
    }
  }
  .p-menu {
    opacity: 1 !important;
    left: auto !important;
  }
  position: relative;
  z-index: 100;
  width: 100%;
  margin-top: 10px;
  &-logo {
    margin-top: 1em;
  }
  .header-row {
    img {
      width: 80%;
      max-width: 75px;
    }

    .columns {
      float: left;
      flex-grow: 1;
      max-width: 100%;
      width: auto;
      padding-left: 0px;
      border-right: 1px solid;
      margin-right: 10px;
      padding-right: 10px;

      &:nth-of-type(3) {
        float: right;
      }
      &:nth-of-type(2) {
        float: right;
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }
}
.error-label {
  font-size: 10px;
  color: $delete;
  padding-left: 30%;
  display: block;
  width: 100%;
  margin-top: 10px;
  overflow: hidden;
}
label {
  font-size: 14px;
  // display: block;
  margin-bottom: 0.5rem;
  margin: 10px;
  .p-dropdown {
    width: 100%;
    margin-top: 6px;
  }
  input {
    font-size: 14px;
    margin-top: 6px;
  }
}

body .p-datatable .p-datatable-tbody > tr {
  &:nth-child(even) {
    background-color: #f3fbfc !important;
    border: none !important;
  }
  td {
    overflow: inherit;
  }
  background-color: transparent !important;
  border: none !important;
}
body {
  .pi {
    font-size: 18px !important;
    font-size: 16px !important;
    margin-right: 15px;
  }

  .p-button-rounded {
    border-radius: 15px;
    padding: 0.45em;
    font-weight: 600;
    font-size: 10px;
    &.right-float {
      float: right;
      margin-left: 20px;
    }
    &:disabled {
      background: lightgray !important;
      border: 1px solid lightgray !important;
    }
  }

  .p-button-text {
    text-transform: uppercase;
    padding: 0 !important;
    display: inline-block;
  }
  .dirty {
    background-color: #e91e69 !important;
    border-color: #e91e69 !important;
  }
  .p-inputtext {
    width: 100%;
  }
  .p-datatable {
    input.p-inputtext.p-component {
      width: 100%;
    }
    .p-datatable-thead th {
      position: relative;
    }
    .p-button {
      .pi {
        &:before {
          font-size: 18px;
          line-height: 10px;
        }
      }
      .p-button-text {
        font-size: 10px;
        letter-spacing: 1px;
      }
      span {
        font-size: 10px;
        letter-spacing: 1px;
        text-transform: uppercase;
        display: inline;
      }
    }
    .datatable-tbody > tr {
      height: auto !important;
      > td {
        padding-bottom: 2em !important;
      }
    }
    padding-bottom: 2em !important;
    .p-sortable-column {
      .p-sortable-column-icon {
        position: absolute;
        top: 12px;
        right: 0px;
        color: #9ac6d9 !important;
      }
    }
    .p-datatable-thead > tr {
      border-top: 2px solid #c8c8c8;
      color: #c8c8c8;
      & > th {
        background-color: transparent !important;
        border: none !important;
        text-align: left !important;
        vertical-align: top;
        font-size: 10px;
        padding: 0 !important;
        text-transform: uppercase;
        font-weight: 200 !important;
        padding-right: 2em !important;
        padding-bottom: 2em !important;
        padding-top: 1em !important;
        padding-left: 1em !important;
      }
    }
    .enumchron-col {
      word-break: break-word;
    }
  }
}
body .p-datatable .p-datatable-header {
  .filters-wrapper {
    text-align: left;
    float: left;
    width: 60%;
  }
  .filters-wrapper--wide {
    width: 80%;
  }
  margin-bottom: 1em;
  clear: both;
  .filter-wrapper {
    &:first-of-type {
      width: 24.5%;
    }
    &:last-of-type {
      margin-right: 0;
    }
    float: left;
    display: block;
    width: 22.5%;
    margin-bottom: 20px;
    margin-right: 2.5%;
    text-align: left;
    .p-dropdown,
    input {
      width: 100%;
      margin-top: 5px;
      label {
        display: block;
        font-size: inherit;
      }
    }
    label {
      display: inline;
      font-size: 12px;
    }
  }
}
body .p-datatable .p-datatable-header,
body .p-datatable .p-datatable-footer {
  background-color: transparent !important;
  border: none !important;
}
body .p-paginator {
  background-color: transparent !important;
  border: transparent !important;
}

.wrapper {
  border-radius: 0.2em;
  background: white;
  padding: 0.5em;
  margin-top: 2em;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.15);
  margin-left: 150px;
  h2 {
    border-bottom: 1px solid #c8c8c8;
    padding-bottom: 0.5em;
    font-size: 18px !important;
  }
  h3 {
    padding-bottom: 0.5em;
    font-size: 16px !important;
    font-weight: 800;
  }
}

.menu-fixed {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  font-size: 14px;
  display: block;
  position: fixed;
  background: linear-gradient(#27292b, #2e3235 60%);
  height: 100%;
  width: 125px;
  h1 {
    font-size: 0px;
    height: 70px;
    margin-top: 20px;
    img {
      width: 70%;
      display: block;
      margin: 0 auto;
      padding: 0 0.75em;
      padding-left: 0.05em;
    }
  }
  -webkit-box-shadow: inset -10px 0px 12px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset -10px 0px 12px -6px rgba(0, 0, 0, 0.75);
  box-shadow: inset -10px 0px 12px -6px rgba(0, 0, 0, 0.75);

  a {
    color: white;
    width: 100%;
    height: 100%;
    display: block;
    min-height: 4em;
    padding-top: 1em;
    padding-left: 0;
    .octicon {
      float: left;
      width: 15px;
    }
    span {
      float: left;
      margin-left: 5%;
      width: 55%;
    }
    &:hover {
      color: white;
    }
  }
  ul {
    padding: 0;
    li {
      min-height: 4em;
      border-bottom: 1px solid $MediumGray;
      overflow: hidden;
      padding-bottom: 0.6em;

      clear: both;
      &.active {
        background-color: $DarkBlue;
      }
    }
  }
  img {
    width: 100%;
  }

  li a {
    border-radius: 0 !important;
  }
  display: block;
  position: fixed;
  background: linear-gradient(#191c20, #24292e 15%);
  height: 100%;
  padding-bottom: 1em;

  -webkit-box-shadow: inset -10px 0px 12px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset -10px 0px 12px -6px rgba(0, 0, 0, 0.75);
  box-shadow: inset -10px 0px 12px -6px rgba(0, 0, 0, 0.75);

  li.p-highlight a {
    background: #3688a3;
    opacity: 0.85;
    span {
      color: white;
    }
    .p-tabview-left-icon {
      color: white;
    }
    .p-tabview-title {
      color: white;
    }
  }

  .p-unselectable-text {
    width: 100%;
    a {
      width: 100%;
      background: none;
      border: none;
      color: #adb5bd;
      border-bottom: 1px solid #23272b;
      border-top: 1px solid #3c4042;
      .p-tabview-title {
        color: #adb5bd;
        width: 100%;
        font-size: 11px;
        display: block;
        font-weight: 300;
        height: auto;
        overflow: hidden;
      }
    }
  }
}
.nav-tabs {
  width: 100%;
}

.p-datatable {
  &-wrapper {
    clear: both;
  }
  .p-button {
    &.issn {
      background-color: #3d7f7c !important;
      border: 1px solid #3d7f7c !important;
      padding: 0.25 1.25em;
    }
  }
  input.p-inputtext.p-component {
    border-color: #a6a6a6;
  }
  .p-datatable-tbody > tr > td {
    border: none !important;
    border-bottom: 1px solid #c8c8c8 !important;
    vertical-align: top;
    padding: 1em 0.75em !important;
  }
  .p-inputswitch {
    width: auto;
  }
  input.p-inputtext.p-component {
    width: auto;
  }
  ul.p-tabview-nav {
    li a {
      border-radius: 0 !important;
    }
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(#191c20, #24292e 15%);
    height: 100%;
    padding-top: 5em;
    width: 10%;

    -webkit-box-shadow: inset -10px 0px 12px -6px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: inset -10px 0px 12px -6px rgba(0, 0, 0, 0.75);
    box-shadow: inset -10px 0px 12px -6px rgba(0, 0, 0, 0.75);

    li.p-highlight a {
      background: #3688a3;
      opacity: 0.85;
      span {
        color: white;
      }
      .p-tabview-left-icon {
        color: white;
      }
      .p-tabview-title {
        color: white;
      }
    }

    .p-unselectable-text {
      width: 100%;
      a {
        width: 100%;
        background: none;
        border: none;
        color: #adb5bd;
        border-bottom: 1px solid #23272b;
        border-top: 1px solid #3c4042;
        .p-tabview-title {
          color: #adb5bd;
          width: 100%;
          overflow: hidden;
          font-size: 11px;
          display: block;
          font-weight: 300;
          height: auto;
          overflow: hidden;
        }
      }
    }
  }
}
div[role="progressbar"] {
  margin: 1rem auto;
}
.p-progress-spinner {
  width: 30px;
  height: 30px;
  margin-left: 20px;
  vertical-align: middle;
}
.p-button .p-button-text {
  display: inline-block !important;
}
.p-button-icon-only .p-button-icon-left,
.p-button-text-icon-left .p-button-icon-left,
.p-button-text-icon-right .p-button-icon-right {
  height: auto !important;
  margin-top: 0 !important;
  position: relative !important;
  top: 0 !important;
  display: inline-block;
}

/*
  holdings-list
  */
.holdings-list {
  span {
    font-size: 12px;
  }
}
.library-wrapper {
  &-edit {
    width: 100%;
  }
  &-header {
    position: absolute;
    right: 20px;
    top: 30px;
  }
  &-code-message {
    display: block;
    margin-right: 12px;
    line-height: 35px;
  }
}
.round-number {
  background-color: #def4fb;
  border-radius: 30px;
  display: inline-block;
  text-align: center;
  color: $teal;
  padding: 5px;
  margin: 0 auto;
  border: 1px solid #8fb8c5;
}

.intro {
  &-logo-wrapper {
    background: linear-gradient(#191c20, #24292e 50%);
    height: 100%;
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
    color: #c8c8c8;
    .pi-spinner {
      display: block;
      margin: 50px auto;
      &:before {
        font-size: 36px !important;
      }
    }
  }
  &-logo {
    width: 25%;
    display: block;
    margin: 125px auto;
    opacity: 0.25;
  }
}

[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

[type="file"] + label {
  background: #f15d22;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  // font-family: 'Poppins', sans-serif;
  font-size: inherit;
  font-weight: 600;
  margin-bottom: 1rem;
  outline: none;
  padding: 1rem 50px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;

  &:hover {
    background-color: darken(#f15d22, 10%);
  }

  &.btn-2 {
    background-color: #24282e;
    border-radius: 15px;
    overflow: hidden;
    font-size: 12px;
    text-transform: uppercase;
    padding: 9px 25px;
    padding-left: 50px;
    margin: 0;
    line-height: 12px;
    &::before {
      color: #fff;
      content: "\E958";
      font-family: "primeicons";
      font-size: 100%;
      line-height: 12px;
      position: absolute;
      transition: all 0.3s;
      font-size: 22px;
      left: 10px;
    }

    &:hover {
      background-color: darken(#99c793, 30%);
    }
  }
}

.checkbox-list {
  width: 25%;
  display: inline-block;
  label {
    margin-left: 20px;
  }
}

.label-wrapper {
  width: 25%;
}
.pmid-wrapper {
  display: block;
  label {
    display: block;
  }
  input {
    width: 25%;
  }
}
input#pmid_value,
input#doi_value,
input#branchcode {
  width: 225px !important;
  margin-right: 20px;
}

.issn-check-wrapper {
  input {
    width: 80%;
  }

  .search {
    float: right;
    &-same-titles {
      span {
        input {
          width: auto;
          display: inline-block;
        }
      }
    }
  }
}

.spacer {
  width: 20px;
  display: inline-block;
}
.root-wrap {
  & label {
    font-weight: 600;
    letter-spacing: -0.5px;
    input {
      margin-top: 5px;
    }
  }
  .p-tabview.p-tabview-top > .p-tabview-nav li {
    top: 2px;
  }
  .p-tabview.p-tabview-top .p-tabview-nav {
    border-bottom: 1px solid #dee2e6 !important;
    padding-bottom: 2px;
  }

  .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight a,
  .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight a,
  .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight a,
  .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight a {
    background-color: #007ad9;
    border: 1px solid;
    color: #ffffff;
    background-color: white !important;
    border-bottom: 1px solid white;
    margin-bottom: 0px;
    color: #444444 !important;
    border-color: #dee2e6 #dee2e6 #fff !important;
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .p-tabview.p-tabview-top .p-tabview-nav li a,
  .p-tabview.p-tabview-bottom .p-tabview-nav li a,
  .p-tabview.p-tabview-left .p-tabview-nav li a,
  .p-tabview.p-tabview-right .p-tabview-nav li a {
    border: 1px solid transparent;
    background-color: white !important;
    float: none;
    display: inline-block;
    color: #333333;
    padding: 0.571em 1em;
    font-weight: 700;
    -webkit-transition: background-color 0.2s, box-shadow 0.2s;
    transition: background-color 0.2s, box-shadow 0.2s;
  }
  .p-tabview .p-tabview-nav li .p-tabview-left-icon,
  .p-tabview .p-tabview-nav li .p-tabview-right-icon,
  .p-tabview .p-tabview-nav li .p-tabview-title {
    vertical-align: middle;
    font-weight: 500;
  }
  .p-highlight {
    border-bottom: 3px solid #fff;
    top: 4px !important;
    span {
      font-weight: 800 !important;
    }
  }
}

div#branch_alt_type {
  width: 65%;
}

.home-dash-wrapper {
  .row {
    font-size: 14px;
    border-bottom: 1px solid #24292e;
    margin-bottom: 1em;
    padding-bottom: 0.5em;
    div:last-of-type {
      text-align: right;
    }
  }
}
.route-wrapper {
  padding: 1.5em;
}
.row {
  width: 100%;
}

.homepage-admin-uploads {
  .row {
    width: 100%;
    padding: 1em 0;
    border-bottom: 1px solid #c8c8c8;
    .col-2 {
      text-align: right;
    }
  }
}

body .p-disabled,
body .p-component:disabled {
  opacity: 1;
}

.p-datatable .p-datatable-tbody > tr > td {
  font-size: 14px !important;
  text-align: justify;
  font-weight: 400 !important;
}
.p-datatable .p-datatable-thead > tr > th {
  font-weight: 700 !important;
}

.public {
  .nav-tabs {
    margin-top: 0;
  }
  .col-md-12,
  .col-12 {
    padding: 0;
    .navbar {
      padding: 0.5em 1em;
    }
  }
  .tab-content {
    padding: 2em;
  }
  table td:first-of-type,
  table th:first-of-type {
    width: 200px;
  }
  .row {
    margin: 0 !important;
  }
  &.header-menu {
    a,
    .nav-link {
      color: #fff;
      &:hover {
        color: $MediumBlue;
      }
    }
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    .left-intro-logo {
      max-width: 100px;
    }
  }
  h2 {
    font-size: 2em;
  }
  .header-wrapper {
    padding: 1em;
  }
  .input-data-wrapper {
    label {
      color: #007ad9;
    }
  }
}

.p-dialog-content {
  button {
    margin-right: 20px;
  }
}

#upload-form {
  .duplicates {
    &-label {
      font-weight: 800;
      vertical-align: inherit;
      margin-left: 5px;
    }
    margin-left: 20px;
    vertical-align: middle;
  }
}

.holdings {
  &-upload-errors {
    overflow: hidden;
    color: $delete;
    &-groups {
      overflow: auto;
      height: 100px;
    }
    h6 {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.success-message {
  color: $teal;
  overflow: auto;
  height: 100px;
}
.error {
  font-size: 14px;
  color: $delete;
}
